import React from 'react';
import './App.css';
import logo from './logo.png'; // Assurez-vous d'avoir un fichier logo.png dans le dossier src

function App() {
  const numCircles = 20; // Nombre de cercles à afficher
  const circles = [];

  for (let i = 0; i < numCircles; i++) {
    const size = `${10 + Math.random() * 20}vmin`;
    const style = {
      width: size,
      height: size,
      top: `${Math.random() * 100}vh`,
      left: `${Math.random() * 100}vw`,
      animationDuration: `${10 + Math.random() * 20}s`,
      animationDelay: `${Math.random() * 20}s`,
    };
    circles.push(<div key={i} className="circle" style={style}></div>);
  }

  return (
    <div className="App">
      {circles}
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Site en construction</p>
        <p className="sub-text">Merci de votre patience. Un nouveau monde est en préparation.</p>
      </header>
    </div>
  );
}

export default App;